import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CboRole } from '@cbo/shared-library';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useUsers } from '../../contexts/userContext';
import isPermitted from '../../lib/permissions';
import GeneralLedgerAccountsInfo from './GeneralLedgerAccountsInfo';
import GeneralLedgerAccountsCreateEdit from './GeneralLedgerAccountsCreateEdit';
import { GlAccountRows } from './GeneralLedgerAccountsManageReport';
import { useSnackbar } from '../../contexts/SnackbarContext';
import useYupValidationResolver from '../../utils/formUtils/yupValidationResolver';
import {
  useCreateGlAccountMutation,
  useDeleteGlAccountMutation,
  useUpdateGlAccountMutation,
} from '../requests/mutations';
import { useGetGlAccountUpsertInfo } from '../requests/queries';
import { CreateGlAccountRequest, GlAccount, UpdateGlAccountRequest } from '../requests/requests';
import GeneralLedgerAccountsDeleteDialog from './GeneralLedgerAccountsDeleteDialog';
import DrawerWrapper from '../../components/DrawerWrapper/DrawerWrapper';
import GeneralLedgerAccountsEditDialog from './GeneralLedgerAccountsEditDialog';
import SalesUtilities from '../utilities';
import UnsavedFormDataPrompt from '../../components/UnsavedDataPrompt/UnsavedFormDataPrompt';

export enum GlAccountsSidebarState {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}

export type GeneralLedgerAccountsSidebarProps = {
  glAccount: GlAccountRows | undefined;
  isOpen: boolean;
  sidebarState: GlAccountsSidebarState;
  setSidebarState: Dispatch<SetStateAction<GlAccountsSidebarState>>;
  onClose: () => void;
  handleRefetch: () => void;
  showDeletionDialog: boolean;
  setShowDeletionDialog: Dispatch<SetStateAction<boolean>>;
  showEditDialog: boolean;
  setShowEditDialog: Dispatch<SetStateAction<boolean>>;
  bulkActionAccountTree: GlAccount[] | undefined;
  setBulkActionAccountTree: Dispatch<SetStateAction<GlAccount[] | undefined>>;
  isDeactivate: boolean;
  setIsDeactivate: Dispatch<SetStateAction<boolean>>;
  fromActionsMenu: boolean;
  setFromActionsMenu: Dispatch<SetStateAction<boolean>>;
  setDirty: Dispatch<SetStateAction<boolean>>;
  attemptingEditWhileDirty: boolean;
  handleUnsavedDialogClosed: () => void;
  setAttemptingEditWhileDirty: Dispatch<SetStateAction<boolean>>;
};

export const defaultGlAccount = {
  accountNumber: null,
  accountName: null,
  categoryId: null,
  accountType: null,
  accountTypeDetail: null,
  parentAccountNumber: null,
  description: '',
  isActive: true,
  isDisplayedInInvoicing: true,
};

function GeneralLedgerAccountsSidebar(props: GeneralLedgerAccountsSidebarProps) {
  const user = useUsers();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    glAccount,
    isOpen,
    sidebarState,
    setSidebarState,
    onClose,
    handleRefetch,
    showDeletionDialog,
    setShowDeletionDialog,
    showEditDialog,
    setShowEditDialog,
    bulkActionAccountTree,
    setBulkActionAccountTree,
    isDeactivate,
    setIsDeactivate,
    fromActionsMenu,
    setFromActionsMenu,
    setDirty,
    attemptingEditWhileDirty,
    handleUnsavedDialogClosed,
    setAttemptingEditWhileDirty,
  } = props;
  const { setSnackbarState } = useSnackbar();
  const history = useHistory();
  const canEdit =
    isPermitted(user, [CboRole.GL_ACCOUNTS_WRITE]) &&
    sidebarState === GlAccountsSidebarState.VIEW &&
    glAccount !== undefined;
  const canCreateOrEdit =
    isPermitted(user, [CboRole.GL_ACCOUNTS_WRITE]) && sidebarState !== GlAccountsSidebarState.VIEW;
  const canView = glAccount && sidebarState === GlAccountsSidebarState.VIEW;

  // form
  const glAccountSchema = yup.object({
    isActive: yup.boolean().required(t('formMessage.required')),
    isDisplayedInInvoicing: yup.boolean().required(t('formMessage.required')),
    accountNumber: yup
      .number()
      .nullable()
      .required(t('formMessage.required'))
      .typeError(
        `${t('admin.glAccountConfiguration.glAccountDrawer.error', {
          label: t('labor.glCode'),
          type: t('admin.glAccountConfiguration.glAccountDrawer.number'),
        })}`
      ),
    accountName: yup.string().nullable().required(t('formMessage.required')),
    categoryId: yup.number().nullable().required(t('formMessage.required')),
    accountType: yup.string().nullable().default(undefined),
    accountTypeDetail: yup
      .string()
      .nullable()
      .default(undefined)
      .optional()
      .when('accountType', {
        is: (val: string) => val !== null && val !== undefined && val.length > 0,
        then: yup.string().nullable().required(t('formMessage.required')),
      }),
    parentAccountNumber: yup.number().nullable().optional().default(undefined),
    description: yup.string().nullable().optional().default(undefined),
  });

  const formContext = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(glAccountSchema),
    defaultValues: glAccount
      ? {
          accountNumber: glAccount.accountNumber,
          accountName: glAccount.accountName,
          categoryId: glAccount.categoryId,
          accountType: glAccount.accountType,
          accountTypeDetail: glAccount.accountType,
          parentAccountNumber: glAccount.parentGLAccount?.accountNumber,
          description: glAccount.description,
          isActive: glAccount.isActive,
          isDisplayedInInvoicing: glAccount.isDisplayedInInvoicing,
        }
      : defaultGlAccount,
  });
  const { control, reset, setValue, trigger, watch, formState } = formContext;

  useEffect(() => {
    if (glAccount && sidebarState === GlAccountsSidebarState.EDIT) {
      reset({
        accountNumber: glAccount.accountNumber,
        accountName: glAccount.accountName,
        categoryId: glAccount.categoryId,
        accountType: glAccount.accountType || null,
        accountTypeDetail: glAccount.accountTypeDetail || null,
        parentAccountNumber: glAccount.parentGLAccount?.accountNumber || null,
        description: glAccount.description || '',
        isActive: glAccount.isActive,
        isDisplayedInInvoicing: glAccount.isDisplayedInInvoicing,
      });
    }
  }, [glAccount, reset, sidebarState]);

  const watchAccountType = watch('accountType');

  const [requestError, setRequestError] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [attemptedCloseWhileDirty, setAttemptedCloseWhileDirty] = useState(false);

  useEffect(() => {
    if (setDirty) setDirty(formState.isDirty);

    // When form is unmounted, always make sure dirty state is set to false
    return () => {
      if (setDirty) setDirty(false);
    };
  }, [formState.isDirty, setDirty]);

  const onCloseDrawer = () => {
    setRequestError(false);
    onClose();
    reset(defaultGlAccount);
    setAttemptedCloseWhileDirty(false);
  };

  const attemptCloseDialog = () => (formState.isDirty ? setAttemptedCloseWhileDirty(true) : onCloseDrawer());

  // api requests
  const createGlAccountMutation = useCreateGlAccountMutation();
  const { mutateAsync: deleteGlAccount } = useDeleteGlAccountMutation();
  const { mutateAsync: updateGlAccount, isLoading } = useUpdateGlAccountMutation();
  const { upsertInfo, refetchUpsertInfo } = useGetGlAccountUpsertInfo();
  const [updateRequestData, setUpdateRequestData] = useState<CreateGlAccountRequest | undefined>(undefined);

  const handleUpsertInfoRefetch = async () => {
    await queryClient.invalidateQueries({ queryKey: ['gl-accounts', 'upsert-info'] });
    await refetchUpsertInfo();
  };

  const handleUndoCreate = async (accountId: string) => {
    const result = await deleteGlAccount({
      accountIds: [accountId],
    });
    if (result) {
      handleRefetch();
      setSnackbarState({
        open: false,
      });
    }
    onCloseDrawer();
  };

  const onSuccess = async () => {
    onCloseDrawer();
    setShowDeletionDialog(false);
    setShowEditDialog(false);
    handleUpsertInfoRefetch();
    await queryClient.invalidateQueries({ queryKey: ['gl-accounts', 'names'] });
  };

  const onError = (errorMessage: string | undefined) => {
    setAlertContent(errorMessage?.toString() ?? t('sales.errors.genericMessage'));
    setRequestError(true);
    if (errorMessage) {
      if (
        SalesUtilities.formErrorCheck(
          errorMessage,
          t('admin.glAccountConfiguration.generalLedgerAccounts.accountNumber')
        )
      ) {
        setValue('accountNumber', undefined);
        trigger('accountNumber', { shouldFocus: true });
      }
    }
  };

  const handleUpdate = async (requestData: CreateGlAccountRequest) => {
    const id = glAccount ? glAccount.accountId : '';
    const accountIds = SalesUtilities.getAllGlAccountIdsForUpdate(glAccount as GlAccount);
    const body: UpdateGlAccountRequest = { ...requestData, isActiveAffectedAccountIds: accountIds };
    let updateBody: UpdateGlAccountRequest = body;
    if (!body.accountType) {
      updateBody = { ...body, accountTypeDetail: undefined };
    }
    const result = await updateGlAccount({ glAccountId: id, body: updateBody });
    if (result === true) {
      onSuccess();
      setSnackbarState({
        open: true,
        message: (
          <Trans
            i18nKey='admin.glAccountConfiguration.actionContent.successMessageNoChildren'
            values={{
              glAccountNumber: requestData.accountNumber,
              glAccountName: requestData.accountName,
              action: t('admin.glAccountConfiguration.actionContent.updated'),
            }}
            components={{ primary: <Typography sx={{ fontWeight: '500' }} display='inline' /> }}
          />
        ),
        color: 'success',
      });
    } else {
      onError(result?.toString());
    }
  };

  const handleSave = async (requestData: CreateGlAccountRequest) => {
    setRequestError(false);
    if (sidebarState === GlAccountsSidebarState.EDIT) {
      if (glAccount && glAccount.isActive !== requestData.isActive) {
        setIsDeactivate(!requestData.isActive);
        setUpdateRequestData(requestData);
        if (requestData.isActive && glAccount.subAccounts && glAccount.subAccounts.length > 0) {
          setShowEditDialog(true);
        } else if (requestData.isActive) {
          handleUpdate(requestData);
        } else {
          setShowEditDialog(true);
        }
      } else {
        handleUpdate(requestData);
      }
    } else if (sidebarState === GlAccountsSidebarState.CREATE) {
      const result = await createGlAccountMutation.mutateAsync(requestData);
      if (result?.accountId) {
        onSuccess();
        setSnackbarState({
          open: true,
          message: (
            <Trans
              i18nKey='admin.glAccountConfiguration.actionContent.successfulCreate'
              values={{
                glAccount: requestData.accountNumber,
                glAccountName: requestData.accountName,
              }}
              components={{ primary: <Typography sx={{ fontWeight: '500' }} display='inline' /> }}
            />
          ),
          color: 'success',
          actionText: t('buttonText.undo'),
          actionCallback: () => {
            handleUndoCreate(result.accountId);
          },
        });
      } else {
        onError(result?.toString());
      }
    }
  };

  const accountForDialog = () => {
    if (glAccount) {
      return [glAccount];
    }
    if (bulkActionAccountTree && bulkActionAccountTree.length > 0) {
      return bulkActionAccountTree;
    }
    return [];
  };

  const onConfirmPromptHandler = () => (attemptedCloseWhileDirty ? onCloseDrawer() : handleUnsavedDialogClosed());
  const onClosePromptHandler = () =>
    attemptedCloseWhileDirty ? setAttemptedCloseWhileDirty(false) : setAttemptingEditWhileDirty(false);

  return (
    <FormContainer onSuccess={(data: CreateGlAccountRequest) => handleSave(data)} formContext={formContext}>
      <UnsavedFormDataPrompt
        open={attemptedCloseWhileDirty || attemptingEditWhileDirty}
        onConfirm={onConfirmPromptHandler}
        onCancel={onClosePromptHandler}
        when={formState.isDirty}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={(location) => formState.isDirty}
      />
      <DrawerWrapper
        open={isOpen}
        closeDrawer={attemptCloseDialog}
        headerActionButtonText={canEdit ? t('buttonText.edit') : undefined}
        headerActionButtonType='outlined'
        onHeaderActionClick={() => setSidebarState(GlAccountsSidebarState.EDIT)}
        hasDeleteButton={canEdit}
        onActionsDeleteClick={() => setShowDeletionDialog(true)}
        data-testid='gl-account-sidebar-container'
        hasSaveFooter={sidebarState !== GlAccountsSidebarState.VIEW}
        hasCancelButton={false}
        useProcessingFlow
        isProcessing={isLoading || createGlAccountMutation.isLoading}
      >
        {canCreateOrEdit && (
          <GeneralLedgerAccountsCreateEdit
            control={control}
            requestError={requestError}
            alertContent={alertContent}
            accountTypeStatus={watchAccountType}
            sidebarState={sidebarState}
            glAccount={glAccount}
            upsertInfo={upsertInfo}
          />
        )}
        {canView && <GeneralLedgerAccountsInfo glAccount={glAccount} />}
        {accountForDialog().length > 0 && (
          <>
            <GeneralLedgerAccountsEditDialog
              open={showEditDialog}
              deactivate={isDeactivate}
              onClose={() => setShowEditDialog(false)}
              handleUpdate={handleUpdate}
              glAccounts={accountForDialog()}
              data={updateRequestData}
              onSuccess={onSuccess}
              bulkAction={bulkActionAccountTree && bulkActionAccountTree.length > 0}
              setBulkActionAccountTree={setBulkActionAccountTree}
              fromActionsMenu={fromActionsMenu}
              setFromActionsMenu={setFromActionsMenu}
            />
            <GeneralLedgerAccountsDeleteDialog
              open={showDeletionDialog}
              onClose={() => setShowDeletionDialog(false)}
              onSuccess={onSuccess}
              glAccounts={accountForDialog()}
              data-testid='gl-account-sidebar-delete-modal'
              fromActionsMenu={fromActionsMenu}
              setFromActionsMenu={setFromActionsMenu}
              setBulkActionAccountTree={setBulkActionAccountTree}
            />
          </>
        )}
      </DrawerWrapper>
    </FormContainer>
  );
}

export default GeneralLedgerAccountsSidebar;
