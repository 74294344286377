import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useMemo, useState } from 'react';

export type IOrganizationDialogContext = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  handleOrganizationClick: (value: string) => void;
};

export const OrganizationDialogContext = createContext<IOrganizationDialogContext>({} as IOrganizationDialogContext);

export const useOrganizationDialogContext = () => useContext(OrganizationDialogContext);

export function OrganizationDialogProvider({
  updateOrganization,
  setIsOrgDialogOpen,
  children,
}: {
  updateOrganization: (org: string) => void;
  setIsOrgDialogOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}) {
  const [searchValue, setSearchValue] = useState('');

  const handleOrganizationClick = useCallback(
    (value: string) => {
      setIsOrgDialogOpen(false);
      setSearchValue('');
      updateOrganization(value);
    },
    [setIsOrgDialogOpen, setSearchValue, updateOrganization]
  );

  const contextValue = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      handleOrganizationClick,
    }),
    [searchValue, setSearchValue, handleOrganizationClick]
  );

  return <OrganizationDialogContext.Provider value={contextValue}>{children}</OrganizationDialogContext.Provider>;
}
