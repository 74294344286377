import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ArrowDropDownSharp from '@mui/icons-material/ArrowDropDownSharp';
import { TFunction, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import RuleTypography from '../RuleTypography/RuleTypography';
import {
  BreakRuleNames,
  BreakRuleTypes,
  LaborRule,
  LaborRuleConfigData,
  LaborRuleGroup,
  MinorRuleTypes,
  OvertimeRuleTypes,
  RuleChangeReason,
  SchedulingRuleTypes,
  WagesRuleTypes,
  ruleTypesByGroup,
} from '../models/LaborRuleConfiguration';
import { getTemplateString, isBreakRule, isPredictabilityPayRule, isTippedMinWageRule } from '../utils/utils';

export interface RuleGuidanceDrawerProps {
  activeTab: LaborRuleGroup;
  jurisdiction: string;
  defaultRuleConfigs: LaborRuleConfigData[];
}

// Jurisdictions here will always show the gov ref links in Scheduling guidance drawer
const schedulingGovRefLinks: Map<string, string> = new Map([
  ['California', 'https://wagesla.lacity.org/sites/g/files/wph1941/files/2023-03/Fair%20Work%20Week%20Ordinance.pdf'],
  ['Pennsylvania', 'https://www.phila.gov/media/20200201213828/Fair-Workweek-Regulations-Final.pdf'],
  ['New York', 'https://www.nyc.gov/site/dca/businesses/fairworkweek-deductions-laws-employers.page#law'],
  ['Oregon', 'https://www.oregon.gov/boli/workers/pages/predictive-scheduling.aspx'],
  ['Washington', 'https://www.seattle.gov/laborstandards/ordinances/secure-scheduling'],
  ['Illinois', 'https://www.chicago.gov/city/en/depts/bacp/supp_info/fairworkweek.html'],
]);

const localExceptionsJurisdicitions: Map<string, SchedulingRuleTypes[]> = new Map([
  [
    'California',
    [
      SchedulingRuleTypes.RightToRestRule,
      SchedulingRuleTypes.PredictabilityPayRule,
      SchedulingRuleTypes.SchedulePublishRule,
    ],
  ],
  ['Pennsylvania', Object.values(SchedulingRuleTypes)],
  ['New York', Object.values(SchedulingRuleTypes)],
  ['Oregon', [SchedulingRuleTypes.PredictabilityPayRule, SchedulingRuleTypes.SchedulePublishRule]],
  ['Washington', Object.values(SchedulingRuleTypes)],
  ['Illinois', Object.values(SchedulingRuleTypes)],
]);

const stateExceptionsJurisdictions: Map<string, string[]> = new Map([
  ['California', [OvertimeRuleTypes.WorkDayTypeRule]],
]);

const federalExceptionsJurisdictions: Map<string, string[]> = new Map([
  ['Alaska', [OvertimeRuleTypes.DailyOvertimeRule]],
  ['California', [OvertimeRuleTypes.DailyOvertimeRule, OvertimeRuleTypes.ConsecutiveDayOvertimeRule]],
  ['Colorado', [OvertimeRuleTypes.DailyOvertimeRule]],
  ['Kentucky', [OvertimeRuleTypes.ConsecutiveDayOvertimeRule]],
  ['Nevada', [OvertimeRuleTypes.DailyOvertimeRule]],
]);

const federalGuidanceStates: string[] = [
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Illinois',
  'Kentucky',
  'Maine',
  'Maryland',
  'Minnesota',
  'Nevada',
  'New Hampshire',
  'New York',
  'North Dakota',
  'Oregon',
  'Rhode Island',
  'Tennessee',
  'Washington',
  'West Virginia',
];
interface CellContent {
  description?: JSX.Element;
  link?: JSX.Element | string | null;
}

const isSchedulingWithSpecialJurisdiction = (activeTab: string, jurisdiction: string) =>
  activeTab === LaborRuleGroup.SCHEDULING && schedulingGovRefLinks.get(jurisdiction);

const groupConfigsByType = (defaultRuleConfigs: LaborRuleConfigData[]) => {
  const groupedConfigs: { [x: string]: LaborRuleConfigData[] } = {
    // FE only types
    [WagesRuleTypes.TippedMinimumWageRule]: [],
    [SchedulingRuleTypes.PredictabilityPayRule]: [],
  };
  defaultRuleConfigs.forEach((config) => {
    groupedConfigs[config?.rule.type] = [];
  });
  defaultRuleConfigs.forEach((config) => {
    if (isTippedMinWageRule(config.rule) && config.rule.isTipped) {
      groupedConfigs[WagesRuleTypes.TippedMinimumWageRule].push(config);
      return;
    }
    if (isPredictabilityPayRule(config.rule)) {
      groupedConfigs[SchedulingRuleTypes.PredictabilityPayRule].push(config);
      return;
    }
    groupedConfigs[config.rule.type]?.push(config);
  });
  return groupedConfigs;
};

const createCellContent = (
  defaultRuleConfigs: LaborRuleConfigData[],
  t: TFunction<'translation', undefined>,
  laborRuleExceptionType?: string
) => {
  let link = defaultRuleConfigs.filter((config) => config?.govRefLink)?.[0]?.govRefLink;
  if (
    defaultRuleConfigs.length === 0 &&
    (laborRuleExceptionType === OvertimeRuleTypes.DailyOvertimeRule ||
      laborRuleExceptionType === OvertimeRuleTypes.ConsecutiveDayOvertimeRule)
  ) {
    link = 'https://www.dol.gov/agencies/whd/state/contacts';
  }

  if (laborRuleExceptionType) {
    return {
      description: (
        <RuleTypography
          rule={laborRuleExceptionType as unknown as LaborRule}
          templateString={t(`laborRules.rules.${laborRuleExceptionType}.exception`)}
        />
      ),
      link: link && (
        <Link fontSize='.75rem' href={link || undefined}>
          {link}
        </Link>
      ),
    };
  }

  return defaultRuleConfigs.length
    ? {
        description: (
          <>
            {defaultRuleConfigs.map((config) => (
              <Box
                key={`${config.id}-${config.ruleName}-container`}
                sx={defaultRuleConfigs.length > 1 ? { mb: '.5rem' } : {}}
              >
                <RuleTypography
                  key={`${config.id}-${config.ruleName}`}
                  rule={config.rule}
                  templateString={getTemplateString(config.rule, t, true)}
                  isDrawer
                />
              </Box>
            ))}
          </>
        ),
        link: (
          <Link fontSize='.75rem' href={defaultRuleConfigs[0]?.govRefLink || undefined}>
            {link}
          </Link>
        ),
      }
    : {
        description: undefined,
        link: link && (
          <Link fontSize='.75rem' href={defaultRuleConfigs[0]?.govRefLink || undefined}>
            {link}
          </Link>
        ),
      };
};

const createDummySchedulingRule = (govLink: string, jurisdiction: string, type: string) => ({
  id: 0,
  name: jurisdiction,
  path: `United_States_of_America.${jurisdiction}`,
  hierarchyId: 0,
  ruleName: type,
  govRefLink: govLink,
  rule: {
    // intentionally set the type to empty string so tempateString in createCellContent will be empty
    type: '',
    group: LaborRuleGroup.SCHEDULING,
  },
  effectiveDate: '2022-10-27',
  changesetId: 100,
  organizationId: '',
  enterpriseUnitId: '',
  defaultRuleId: 0,
  changeReason: RuleChangeReason.INITIALIZATION,
});

const createRowsContent = (
  defaultRuleConfigs: LaborRuleConfigData[],
  t: TFunction<'translation', undefined>,
  activeTab: LaborRuleGroup,
  jurisdiction: string
) => {
  const groupedConfigs = groupConfigsByType(defaultRuleConfigs);

  const isFed = (config: LaborRuleConfigData) => config.path === 'United_States_of_America';
  const createRow = (configGroup: LaborRuleConfigData[], type: string) => ({
    ruleName: type && t(`laborRules.rules.${type}.name`),
    localExceptions:
      localExceptionsJurisdicitions.get(jurisdiction) &&
      localExceptionsJurisdicitions.get(jurisdiction)?.includes(type as SchedulingRuleTypes),
    federalGuidance:
      configGroup &&
      createCellContent(
        configGroup.filter((config) => isFed(config)),
        t,
        federalExceptionsJurisdictions.get(jurisdiction)?.includes(type) ? type : undefined
      ),
    stateGuidance:
      configGroup &&
      createCellContent(
        configGroup.filter((config) => !isFed(config)),
        t,
        stateExceptionsJurisdictions.get(jurisdiction)?.includes(type) ? type : undefined
      ),
  });

  const buildBreaksRows = () =>
    Object.keys(BreakRuleNames).map((type: string) => {
      const isPaid = (config: LaborRuleConfigData) =>
        isBreakRule(config.rule) && config.rule.isPaid === (type === BreakRuleNames.RestBreak);
      const configs = groupedConfigs[BreakRuleTypes.MissedBreakRule];
      const checkedConfigs = configs?.length ? configs : [];
      return createRow(checkedConfigs.filter(isPaid), type);
    });

  const buildSchedulingWithSpecialJurisdictionRows = () => {
    const govLink = schedulingGovRefLinks.get(jurisdiction) ?? '';
    const groupedSchedulingConfigs: { [x: string]: LaborRuleConfigData[] } = {};

    Object.keys(SchedulingRuleTypes).forEach((type) => {
      if (groupedConfigs[type] && groupedConfigs[type].length > 0) {
        groupedSchedulingConfigs[type] = groupedConfigs[type];
        if (groupedSchedulingConfigs[type][0]) {
          groupedSchedulingConfigs[type][0].govRefLink = govLink;
        }
      } else {
        groupedSchedulingConfigs[type] = [createDummySchedulingRule(govLink, jurisdiction, type)];
      }
    });

    return ruleTypesByGroup[activeTab].map((type: string) => createRow(groupedSchedulingConfigs[type], type));
  };

  const buildMinorsRows = () => {
    const minorRules = ruleTypesByGroup[activeTab] as MinorRuleTypes[];
    const filteredRules = minorRules.filter(
      (
        rule: SchedulingRuleTypes | BreakRuleTypes.MissedBreakRule | OvertimeRuleTypes | MinorRuleTypes | WagesRuleTypes
      ) => rule !== MinorRuleTypes.MinorSchoolDayDefinitionRule && rule !== MinorRuleTypes.MinorSchoolDaysRule
    );
    return filteredRules.map((type: string) => createRow(groupedConfigs[type], type));
  };

  if (activeTab === LaborRuleGroup.BREAKS) {
    return buildBreaksRows();
    // eslint-disable-next-line no-else-return
  } else if (isSchedulingWithSpecialJurisdiction(activeTab, jurisdiction)) {
    return buildSchedulingWithSpecialJurisdictionRows();
  } else if (activeTab === LaborRuleGroup.MINORS) {
    return buildMinorsRows();
  }
  return ruleTypesByGroup[activeTab].map((type: string) => createRow(groupedConfigs[type], type));
};

export default function RuleGuidanceDrawer(props: RuleGuidanceDrawerProps) {
  const { activeTab, jurisdiction, defaultRuleConfigs } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const transGuidanceSubStr = 'laborRules.rulesGuidance.';
  const headers = [
    t(`${transGuidanceSubStr}headers.rule`),
    t(`${transGuidanceSubStr}headers.federalGuidance`),
    t(`${transGuidanceSubStr}headers.stateGuidance`),
  ];
  const cellStyle = {
    border: 1,
    borderColor: theme.palette.grey[400],
    verticalAlign: 'baseline',
    fontSize: '1rem',
  };

  const noGuidanceText = (noGuidance: string, noGuidanceFederalState: string, isStatecol?: boolean) => {
    if (activeTab === LaborRuleGroup.BREAKS) {
      if (isStatecol) {
        return noGuidance;
      }
      if (federalGuidanceStates.includes(jurisdiction)) {
        return noGuidanceFederalState;
      }
    }
    return noGuidance;
  };

  const contentCell = (cellContent?: CellContent, localExceptions?: boolean, isStatecol?: boolean) => (
    <TableCell
      sx={{ ...cellStyle, minWidth: '26.5rem', maxWidth: '26.5rem' }}
      align='left'
      data-testid='rule-guidance-cell'
    >
      {cellContent && cellContent.description ? (
        cellContent.description
      ) : (
        <Typography color='text.secondary' data-testid='no-guidance-text'>
          {localExceptions
            ? t(`${transGuidanceSubStr}localExceptions`)
            : noGuidanceText(
                t(`${transGuidanceSubStr}noGuidance`),
                t(`${transGuidanceSubStr}noGuidanceFederalStates`),
                isStatecol
              )}
        </Typography>
      )}
      {cellContent?.link}
    </TableCell>
  );

  const rowsContent = createRowsContent(defaultRuleConfigs, t, activeTab, jurisdiction).filter(
    (row) =>
      ![
        SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule,
        SchedulingRuleTypes.ExtraHoursPredictabilityPayRule,
        SchedulingRuleTypes.FixedFeePredictabilityPayRule,
      ].includes(row.ruleName as SchedulingRuleTypes)
  );

  return (
    <Box>
      <Accordion elevation={0} sx={{ padding: '.5rem', boxShadow: 'none' }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDownSharp />}
          sx={{ display: 'inline-flex', height: '4.5rem' }}
          data-testid='rule-guidance-summary'
        >
          <Typography variant='h6'>{`${t(
            `laborRules.tabs.${activeTab}`
          )} rule guidance for ${jurisdiction}`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table sx={{ width: '68rem' }}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell data-testid={`${header}-header`} key={`${header} key`} align='left' sx={{ padding: '0' }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsContent.map(
                (row) =>
                  row && (
                    <TableRow key={row.ruleName} data-testid={`rule-guidance-${row.ruleName}-row`}>
                      <TableCell
                        sx={{ ...cellStyle, minWidth: '15rem', maxWidth: '15rem' }}
                        align='left'
                        data-testid={`rule-guidance-${row.ruleName}-cell`}
                      >
                        {row.ruleName || t(`${transGuidanceSubStr}noGuidance`)}
                      </TableCell>
                      {contentCell(row.federalGuidance, row.localExceptions)}
                      {contentCell(row.stateGuidance, row.localExceptions, true)}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
          {/* TODO - use real data for disclaimer */}
          <Typography fontSize='.75rem' paddingTop='1.5rem' data-testid='rule-guidance-disclaimer'>
            {t(`${transGuidanceSubStr}disclaimerSource`, {
              source: t(`${transGuidanceSubStr}UsDOL`),
              date: '01/01/2025',
            })}
            {t(`${transGuidanceSubStr}disclaimer`)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
