type TLanguageSettings = { id: string; label: string };

/**
 * Languages supported by CBO
 */
export type TLanguage = 'en-US' | 'es-MX' | 'fr-CA';

/**
 * Settings for supported languages
 */
export const Languages: { [language in TLanguage]: TLanguageSettings } = {
  'en-US': {
    id: 'en-US',
    label: 'English (US)',
  },
  'es-MX': {
    id: 'es-MX',
    label: 'Español (MX)',
  },
  'fr-CA': {
    id: 'fr-CA',
    label: 'French (CA)',
  },
};
