import { SxProps } from '@mui/material/styles';

export const appBarButtonSx: SxProps = {
  color: 'text.secondary',
  fontWeight: 'normal',
  '&:visited, &:focus, &:active': {
    color: 'text.secondary',
    backgroundColor: 'transparent',
  },
  alignItems: 'center',
};

export const appBarButtonSxMobile: SxProps = { ...appBarButtonSx, '& .MuiButton-startIcon': { margin: 0 } };
