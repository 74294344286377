import { Dispatch, SetStateAction, useRef, useState, useEffect, useMemo } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import StickyFooter from '../../components/StickyFooter/StickyFooter';
import SiteHeader from './SiteHeader';

type DrawerProps = {
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  topSites: JSX.Element[];
  handleViewAll: () => void;
};

function SiteSelectorDrawer(props: DrawerProps) {
  const { drawerOpen, setDrawerOpen, topSites, handleViewAll } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [siteScrollHeight, setSiteScrollHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setSiteScrollHeight(ref.current.clientHeight);
    }
  }, [setSiteScrollHeight, ref.current?.clientHeight]);

  const boxShadow = useMemo(() => {
    const totalHeight = document.documentElement.clientHeight;
    // Figuring out what 45% of the total height is since the max height is 45vh.
    return siteScrollHeight >= Math.round(totalHeight * 0.45) ? '0px -4px 12px 0px rgba(0, 0, 0, 0.08)' : 0;
  }, [siteScrollHeight]);

  return (
    <SwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      sx={{
        '.MuiPaper-root.MuiDrawer-paper': {
          borderRadius: '12px 12px 0 0',
          border: 'none',
        },
      }}
      onOpen={() => setDrawerOpen(true)}
      onClose={() => setDrawerOpen(false)}
      open={drawerOpen}
      anchor='bottom'
      data-testid='site-selector-drawer'
    >
      <SiteHeader height='56px' padding='14px 16px 8px 16px' onCloseClick={() => setDrawerOpen(false)} />
      <Box sx={{ overflowY: 'auto', maxHeight: '45vh', marginBottom: '20px' }} ref={ref}>
        {topSites}
      </Box>
      <StickyFooter
        addBoxShadow={false}
        customSx={{
          backgroundColor: theme.palette.background.paper,
          height: '76px',
          padding: '16px',
          borderTop: '1px solid #E5E5E5',
          boxShadow,
        }}
      >
        <Button variant='outlined' fullWidth onClick={handleViewAll}>
          {t('globalFilters.viewAllSites')}
        </Button>
      </StickyFooter>
    </SwipeableDrawer>
  );
}

export default SiteSelectorDrawer;
