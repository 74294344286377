/* eslint-disable @typescript-eslint/no-explicit-any */
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import { isNil } from 'lodash';
import { FocusEvent, KeyboardEvent, forwardRef, useEffect, useRef } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { AutocompleteElement, TextFieldElement } from 'react-hook-form-mui';
import { TFunction } from 'react-i18next';
import { getDropDownHeight } from '../../lib/styles/helpers';
import DatePickerElement from '../FormDateTimeField/DatePickerElement';
import DateTimePickerElement from '../FormDateTimeField/DateTimePickerElement';
import FormMultiSelect from '../FormMultiSelect/FormMultiSelect';

const CustomListboxComponent = forwardRef((rest, ref) => {
  const ulRef = useRef() as any;

  useEffect(() => {
    if (ulRef) {
      const offset = 35;
      const visible: HTMLLIElement[] = [].slice.call(ulRef.current.children).splice(0, 6);
      const height = visible.map((li) => li.getBoundingClientRect().height).reduce((a, b) => a + b, 0) + offset;
      ulRef.current.style.maxHeight = `${height}px`;
    }
  }, [ulRef]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ul ref={ulRef} {...rest} />;
});

export const formatTags = (selectionList: any[]): string => {
  let tags = '';
  if (selectionList?.length === 1 && selectionList[0]) {
    tags = selectionList[0].length > 17 ? `${(selectionList[0] as string).slice(0, 17)}...` : selectionList[0];
  } else if (selectionList?.length > 1 && selectionList[0]) {
    tags = selectionList[0].length > 13 ? `${(selectionList[0] as string).slice(0, 13)}...` : selectionList[0];
    tags += `, +${selectionList.length - 1}`;
  }
  return tags;
};

const dashboardFilterProps = (isDashboardFilter: boolean, isApiFilter: boolean) => {
  if (!isDashboardFilter) return {};

  return {
    openOnFocus: true,
    isOptionEqualToValue: (option: unknown, value: unknown) => option === value,
    renderTags: (selection: any[]) => {
      const selectionList = isApiFilter ? selection.map((s) => s.label) : selection;
      return <span>{formatTags(selectionList)}</span>;
    },
    ListboxComponent: CustomListboxComponent as unknown as any,
    sx: {
      '& .MuiAutocomplete-hasPopupIcon': {
        paddingRight: '0px!important',
      },
      '& .MuiAutocomplete-hasClearIcon': {
        paddingRight: '0px!important',
      },
      '& .MuiAutocomplete-inputRoot': {
        paddingRight: '0px!important',
      },
    },
  };
};

export enum StartAdornmentType {
  SEARCH_ICON,
  DOLLAR_SIGN,
}

const generatePickerTimes = (pickerMinuteInterval: number): { label: string; id: string; value: string }[] => {
  const result: { label: string; id: string; value: string }[] = [];

  const meridians = ['am', 'pm'];
  const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const minutes = [];

  let pmi = pickerMinuteInterval ?? 1;
  pmi = pmi < 1 ? 1 : pmi;
  for (let p = 0; p < 60; p += pmi) {
    minutes.push(p.toString().padStart(2, '0'));
  }

  for (let meridian = 0; meridian < meridians.length; meridian += 1) {
    for (let hour = 0; hour < hours.length; hour += 1) {
      for (let minute = 0; minute < minutes.length; minute += 1) {
        const civilianTimeString = `${hours[hour]}:${minutes[minute]}${meridians[meridian]}`;
        let militaryHour = hours[hour] === 12 ? 0 : hours[hour];
        militaryHour += meridians[meridian] === 'pm' ? 12 : 0;

        result.push({
          label: civilianTimeString,
          id: `${militaryHour.toString().padStart(2, '0')}:${minutes[minute]}`,
          value: civilianTimeString,
        });
      }
    }
  }

  return result;
};

const createFormTextField = (
  // Can specify types on these but left generic for now
  // ex formInfo: TEmployeeInformationFields,
  // ex register: UseFormRegister<IEmployeeInformation>,
  formInfo: FieldValues,
  translation: {
    t: TFunction<'translation', undefined>;
    tKey: string;
  },
  register?: UseFormRegister<FieldValues>,
  overrideSx?: SxProps<Theme> | undefined,
  ariaLabel?: string
): JSX.Element => {
  const {
    name,
    required,
    dataTestId,
    gridProps,
    variant,
    disableOpenPicker,
    inputFormat,
    hasHelperText,
    disableFuture,
    disablePast,
    minDate,
    maxDate,
    disablePlaceholder,
    disableMaskedInput,
    disableHighlightToday,
    readOnly = false,
    editMode = false,
    placeholder,
    handleBlur,
    disabled,
    options,
    multiline,
    maxLength,
    step,
    label,
    disableUnderline,
    showCheckbox,
    multiple,
    isDashboardFilter,
    isApiFilter,
    onChange,
    startAdornmentType, // type of StartAdornmentType
    defaultValue,
    rows,
    inputType,
    error,
    lightMode,
    isInputComplete,
    helperTextVariable,
    inputProps,
  } = formInfo;

  const { ref, ...rest } = register?.(name) ?? {};
  const { t, tKey } = translation;
  const lowercaseInputFormat = inputFormat?.toString().toLowerCase();
  const getHelperText = (needsHelperText: boolean, isDate?: boolean) => {
    if (needsHelperText) {
      if (isDate) {
        return `${t(`${tKey}.${name}HelperText`, { dateFormat: lowercaseInputFormat })}`;
      }
      return t(`${tKey}.${name}HelperText`, helperTextVariable);
    }
    return '';
  };

  const startAdornmentMap: Map<StartAdornmentType, JSX.Element> = new Map([
    [StartAdornmentType.SEARCH_ICON, <SearchIcon />],
    [StartAdornmentType.DOLLAR_SIGN, <Typography color='text.primary'>$</Typography>],
  ]);

  const safeLabel = label ?? t(`${tKey}.${name}`);

  function getTextInputColor(): SxProps<Theme> | undefined {
    if (lightMode) {
      if (isInputComplete) {
        return {
          '& .MuiInputBase-input': {
            color: 'black',
          },
        };
      }
      return {
        '& .MuiInputBase-input': {
          color: 'rgba(0,0,0,0.23)',
        },
      };
    }
    if (isInputComplete) {
      return {
        '& .MuiInputBase-input': {
          color: 'white',
        },
      };
    }
    return {
      '& .MuiInputBase-input': {
        color: 'rgba(255,255,255,0.6)',
      },
    };
  }

  function textFieldElement() {
    switch (variant) {
      case 'datetime':
        return (
          <DateTimePickerElement
            name={name}
            key={name}
            disableFuture
            label={safeLabel}
            {...rest}
            required={required}
            aria-controls={dataTestId}
            handleBlur={handleBlur}
            disabled={disabled}
          />
        );
      case 'date':
        return (
          <DatePickerElement
            name={name}
            key={name}
            label={safeLabel}
            required={required}
            aria-controls={dataTestId}
            disableFuture={disableFuture}
            disablePast={disablePast}
            minDate={minDate}
            maxDate={maxDate}
            disableOpenPicker={disableOpenPicker}
            inputFormat={inputFormat}
            disablePlaceholder={disablePlaceholder}
            disableMaskedInput={disableMaskedInput}
            disableHighlightToday={disableHighlightToday}
            helperText={getHelperText(hasHelperText, true)}
            handleBlur={handleBlur}
            readOnly={readOnly}
            disabled={disabled}
            defaultValue={defaultValue}
            {...rest}
          />
        );
      case 'time':
        return (
          <TextFieldElement
            name={name}
            key={name}
            variant='standard'
            type='time'
            fullWidth
            aria-controls={dataTestId}
            label={safeLabel}
            helperText={getHelperText(hasHelperText, readOnly)}
            required={required}
            inputProps={{ 'data-testid': dataTestId, step, readOnly }}
            disabled={disabled}
            sx={getTextInputColor()}
            {...rest}
            // inputRef is required for TextFields otherwise forward the ref directly other option is setting ref={null}
            inputRef={ref}
          />
        );
      case 'multi-select':
        return (
          <FormMultiSelect
            name={name}
            key={name}
            required={false}
            label={t(`${tKey}.${name}`)}
            options={options}
            dataTestId={dataTestId}
            placeholder={placeholder}
            disabled={disabled}
            {...rest}
          />
        );
      case 'autocomplete':
        return (
          <AutocompleteElement
            label={safeLabel}
            required={required}
            aria-controls={dataTestId}
            name={name}
            textFieldProps={{ variant: 'standard', id: dataTestId, placeholder }}
            options={options}
            showCheckbox={showCheckbox}
            multiple={multiple}
            autocompleteProps={{
              disablePortal: false,
              disabled,
              readOnly,
              ...dashboardFilterProps(isDashboardFilter, isApiFilter),
            }}
          />
        );
      case 'time-autocomplete':
        return (
          <AutocompleteElement
            label={safeLabel}
            required={required}
            aria-controls={dataTestId}
            name={name}
            textFieldProps={{ variant: 'standard', id: dataTestId, placeholder }}
            options={generatePickerTimes(step)}
            showCheckbox={showCheckbox}
            multiple={multiple}
            autocompleteProps={{
              disablePortal: false,
              disabled,
              readOnly,
              ...dashboardFilterProps(isDashboardFilter, isApiFilter),
            }}
          />
        );
      case 'select':
        return (
          <TextFieldElement
            name={name}
            key={name}
            variant='standard'
            fullWidth
            aria-controls={dataTestId}
            label={label ?? t(`${tKey}.${name}`)}
            required
            select
            data-testid={dataTestId}
            placeholder={placeholder}
            inputProps={{ placeholder: 'Select one', onChange, 'aria-label': ariaLabel }}
            sx={overrideSx}
            {...rest}
          >
            {options.map((option: any) => (
              <MenuItem key={`option-${option.value}`} value={option.value} data-testid={`menu-item-${option.value}`}>
                {option.label}
              </MenuItem>
            ))}
          </TextFieldElement>
        );
      case 'select-with-sub-categories':
        return (
          <TextFieldElement
            name={name}
            key={name}
            variant='standard'
            fullWidth
            aria-controls={dataTestId}
            label={t(`${tKey}.${name}`)}
            required
            select
            disabled={disabled}
            data-testid={dataTestId}
            SelectProps={{ MenuProps: { style: { height: getDropDownHeight(36) } } }}
          >
            {options.map((opt: any) =>
              opt.isSubheader ? (
                <ListSubheader key={opt.label}>{opt.label}</ListSubheader>
              ) : (
                <MenuItem key={opt.value} value={opt.value} sx={{ fontSize: '16px', marginLeft: '8px' }}>
                  {!opt.value ? <em>{opt.label}</em> : opt.label}
                </MenuItem>
              )
            )}
          </TextFieldElement>
        );
      case 'counter':
        return (
          <TextFieldElement
            sx={{
              '.MuiInput-root': {
                input: {
                  textAlign: 'center',
                  width: '63px',
                  paddingLeft: '8px',
                },
                '&.Mui-focused input': {
                  textAlign: 'left',
                  paddingLeft: '2px',
                  paddingRight: '6px',
                },
              },
            }}
            name={name}
            type='number'
            variant='standard'
            /* prevents mouse scroll from changing the input */
            onFocus={(e: FocusEvent) =>
              e.target.addEventListener('wheel', (event) => event.preventDefault(), { passive: false })
            }
            onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                e.preventDefault();
              }
            }}
            inputProps={{
              min: 0,
              'data-testid': dataTestId,
            }}
            inputRef={ref}
          />
        );
      case 'standard':
      default:
        return (
          <TextFieldElement
            name={name}
            key={name}
            error={error}
            variant='standard'
            fullWidth
            aria-controls={dataTestId}
            label={safeLabel}
            required={required}
            type={inputType ?? 'text'}
            sx={overrideSx}
            inputProps={{
              'data-testid': dataTestId,
              readOnly,
              onBlur: handleBlur,
              maxLength,
              onChange,
              'aria-label': ariaLabel,
            }}
            InputLabelProps={{ shrink: true }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              disableUnderline,
              startAdornment: (
                <>
                  {!isNil(startAdornmentType) && (
                    <InputAdornment position='start'>
                      {startAdornmentMap.get(startAdornmentType as StartAdornmentType)}
                    </InputAdornment>
                  )}

                  {readOnly && editMode && (
                    <Box
                      data-testid={`${dataTestId}-lockIcon`}
                      sx={{ position: 'absolute', right: 0, width: '24px', height: '24px' }}
                    >
                      <LockIcon />
                    </Box>
                  )}
                </>
              ),
              ...inputProps,
            }}
            placeholder={placeholder}
            helperText={getHelperText(hasHelperText, readOnly)}
            disabled={disabled}
            {...rest}
            // inputRef is required for TextFields otherwise forward the ref directly other option is setting ref={null}
            inputRef={ref}
            multiline={multiline}
            rows={rows}
          />
        );
    }
  }

  return (
    <Grid item key={`grid-container-${name}`} {...gridProps}>
      {textFieldElement()}
    </Grid>
  );
};

export default createFormTextField;
