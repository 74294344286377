import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useSchedulingRequests from '.';
import staleTimeSettings from '../../../config/reactQueryConfig';
import { Paginated } from '../../../models/Paginated';
import {
  ActualShiftResponseDto,
  PlannedShiftResponseDto,
  SalesSummaryData,
  SalesSummaryDataBodySchema,
  ScheduleResponse,
  WorkWeekConfig,
  WorkWeekConfigResponse,
} from '../types';
import queryKeys from './queryKeys';
import { useSites } from '../../../contexts/siteContext';

export const useSchedulesQuery = (
  calendarWeekStart: string,
  calendarWeekEnd: string,
  enabled: boolean
): UseQueryResult<ScheduleResponse[]> => {
  const { getSchedules } = useSchedulingRequests();

  return useQuery(
    queryKeys.schedules.listByCalendarWeek(calendarWeekStart, calendarWeekEnd),
    () => getSchedules(calendarWeekStart, calendarWeekEnd),
    {
      staleTime: staleTimeSettings.LOW,
      useErrorBoundary: true,
      keepPreviousData: true,
      enabled,
    }
  );
};

export const useScheduleBySelfQuery = (
  calendarWeekStart: string,
  calendarWeekEnd: string,
  enabled: boolean
): UseQueryResult<ScheduleResponse[]> => {
  const { getSchedulesBySelf } = useSchedulingRequests();

  return useQuery(
    queryKeys.schedules.selfListByCalendarWeek(calendarWeekStart, calendarWeekEnd),
    () => getSchedulesBySelf(calendarWeekStart, calendarWeekEnd),
    {
      staleTime: staleTimeSettings.LOW,
      useErrorBoundary: true,
      keepPreviousData: true,
      enabled,
    }
  );
};

export const usePlannedShiftsQuery = (
  page: number,
  limit: number,
  startDate: string,
  endDate: string,
  isAuthenticated: boolean
): UseQueryResult<Paginated<PlannedShiftResponseDto>> => {
  const { getPlannedShifts } = useSchedulingRequests();
  const { selectedSite } = useSites();
  return useQuery(
    queryKeys.schedules.plannedShiftsByRange(startDate, endDate, selectedSite.enterpriseUnitId),
    () => getPlannedShifts(page, limit, startDate, endDate),
    {
      staleTime: staleTimeSettings.LOW,
      useErrorBoundary: true,
      keepPreviousData: true,
      enabled: isAuthenticated,
    }
  );
};

export const useWorkWeekConfigQuery = (
  isAuthenticated: boolean
): UseQueryResult<WorkWeekConfig | WorkWeekConfigResponse> => {
  const { getWorkWeekConfig } = useSchedulingRequests();

  return useQuery(queryKeys.schedules.dayOfWeekStart, () => getWorkWeekConfig(), {
    staleTime: staleTimeSettings.HIGH,
    useErrorBoundary: true,
    keepPreviousData: true,
    enabled: isAuthenticated,
  });
};

export const useActualShiftsQuery = (
  page: number,
  limit: number,
  startDate: string,
  endDate: string,
  isAuthenticated: boolean,
  employeeId?: string
): UseQueryResult<Paginated<ActualShiftResponseDto>> => {
  const { selectedSite } = useSites();
  const { getActualShifts } = useSchedulingRequests();

  return useQuery(
    queryKeys.schedules.actualShiftsByRange(startDate, endDate, selectedSite.enterpriseUnitId, employeeId),
    () => getActualShifts(page, limit, startDate, endDate, employeeId),
    {
      staleTime: staleTimeSettings.LOW,
      useErrorBoundary: true,
      keepPreviousData: true,
      enabled: isAuthenticated,
    }
  );
};

export const useSalesSummaryQuery = (
  scheduleId: string | undefined,
  enabled: boolean,
  payload: SalesSummaryDataBodySchema
): UseQueryResult<SalesSummaryData[]> => {
  const { getSalesSummary } = useSchedulingRequests();

  return useQuery(
    queryKeys.schedules.summarySales(scheduleId || ''),
    () =>
      scheduleId
        ? getSalesSummary(scheduleId, payload)
        : [{} as SalesSummaryData, {} as SalesSummaryData, {} as SalesSummaryData],
    {
      staleTime: staleTimeSettings.LOW,
      useErrorBoundary: false,
      keepPreviousData: false,
      enabled,
    }
  );
};
