import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { SxProps, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dispatch, SetStateAction, useState } from 'react';
import { SiteInfo } from '@cbo/shared-library';
import SearchBar from '../../components/GlobalFilterBar/SearchBar/SearchBar';
import AllSitesList from './AllSitesList';
import SiteHeader from './SiteHeader';

type SiteSelectorDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  handleSiteSelection: (site: SiteInfo) => void;
};

function SiteSelectorDialog(props: SiteSelectorDialogProps) {
  const { dialogOpen, setDialogOpen, handleSiteSelection } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [siteSearch, setSiteSearch] = useState('');

  const paperSx: SxProps<Theme> = isMobile
    ? { width: '100%', height: '100%', margin: 0, borderRadius: 0, maxHeight: '100%' }
    : { width: '500px', height: '612px' };
  const titlePadding = isMobile ? '16px' : '24px';
  const searchBarPadding = isMobile ? '0px 0px 16px' : '0px 8px 16px 8px';
  const contentPadding = isMobile ? '0px 16px' : '0px 24px';
  const contentMarginBottom = isMobile ? '0px' : '24px';

  return (
    <Dialog open={dialogOpen} PaperProps={{ sx: paperSx }}>
      <SiteHeader height='80px' padding={titlePadding} onCloseClick={() => setDialogOpen(false)} />
      <Box sx={{ padding: searchBarPadding, height: '64px' }}>
        <SearchBar showClearIcon={false} searchValue={siteSearch} setSearchValue={setSiteSearch} />
      </Box>
      <DialogContent sx={{ overflowY: 'auto', padding: contentPadding, marginBottom: contentMarginBottom }}>
        <AllSitesList searchValue={siteSearch} handleSiteSelection={handleSiteSelection} />
      </DialogContent>
    </Dialog>
  );
}

export default SiteSelectorDialog;
