import { useAccountPreferences } from '../../contexts/accountPreferencesContext';
import { formatCurrency } from '../../utils/formatters';

type CurrencyProps = {
  value: string | number;
  replaceZeroWithEmptyString?: boolean;
  displayPlusSign?: boolean;
};

const defaultProps = {
  replaceZeroWithEmptyString: false,
  displayPlusSign: false,
};

function Currency({ value, replaceZeroWithEmptyString, displayPlusSign }: CurrencyProps) {
  const { preferences } = useAccountPreferences();
  const parsed = Number(value);

  if ((replaceZeroWithEmptyString && parsed === 0) || Number.isNaN(parsed)) {
    return <span> </span>;
  }
  return <span>{formatCurrency(parsed, preferences, undefined, undefined, displayPlusSign)}</span>;
}

Currency.defaultProps = defaultProps;

export default Currency;
