import { Req } from '@cbo/shared-library';
import { useErrorHandler } from 'react-error-boundary';
import { List } from 'lodash';
import useCallBslAndHandleErrors from '../../lib/firebaseApiHandleErrors';
import { LaborRuleConfigData, EnterpriseGroupResponse, CreateChangeSetRequest } from '../models/LaborRuleConfiguration';
import { JurisdictionSitesRow } from '../JurisdictionSitesDataGrid/JurisdictionSitesDataGrid';
import { getLocalityUUID, isEnterpriseGroupResponse } from '../utils/utils';
import { useSites } from '../../contexts/siteContext';
import { LruaRecordData, LruaRecordRequest } from '../models/LruaRecord';

export type LaborRulesRequests = {
  getDefaultRuleConfigurationsByJurisdiction: (jurisdiction: string) => Promise<List<LaborRuleConfigData> | void>;
  getRuleConfigurationsForEnterprise: (jurisdiction: string) => Promise<List<LaborRuleConfigData> | void>;
  postRuleConfigurationForEnterprise: (reqBody: CreateChangeSetRequest) => Promise<void>;
  deleteRuleConfigurations: (changesetIds: number[]) => Promise<unknown[]>;
  getUnitSitesByGroupName: (
    localityNames: string[]
  ) => Promise<(({ id: string; hierarchy: string[] } | undefined)[] | undefined)[]>;
  getLrua: () => Promise<LruaRecordData | void>;
  createLrua: (paylod: LruaRecordRequest) => Promise<LruaRecordData | void>;
};

export const useLaborRulesRequests = (): LaborRulesRequests => {
  const handleError = useErrorHandler();
  const callBslAndHandleErrors = useCallBslAndHandleErrors();
  const { selectedSite } = useSites();

  const proxies = {
    rule_configs: 'labor-rules',
    schedules: 'schedules',
  };

  const Verb = Req.Firebase.HttpVerb;
  /**
   * RULE CONFIGURATIONS
   */
  const getDefaultRuleConfigurationsByJurisdiction = async (
    jurisdiction: string
  ): Promise<List<LaborRuleConfigData> | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.rule_configs,
        pathSegments: ['config', jurisdiction],
        verb: Verb.GET,
      },
      handleError
    );

  const getRuleConfigurationsForEnterprise = async (jurisdiction: string): Promise<List<LaborRuleConfigData> | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.rule_configs,
        pathSegments: ['config', 'org', jurisdiction],
        verb: Verb.GET,
      },
      handleError
    );

  const postRuleConfigurationForEnterprise = async (reqBody: CreateChangeSetRequest): Promise<void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.rule_configs,
        pathSegments: ['config', 'org', 'changeset'],
        verb: Verb.POST,
        payload: reqBody,
      },
      handleError
    );

  const deleteRuleConfigurations = async (changesetIds: number[]): Promise<unknown[]> =>
    Promise.all(
      changesetIds.map((id) =>
        callBslAndHandleErrors(
          {
            proxy: proxies.rule_configs,
            pathSegments: ['config', 'org', 'changeset', id.toString()],
            verb: Verb.DELETE,
          },
          handleError
        )
      )
    );

  /**
   * SITES BY ENTERPRISE UNIT ID
   */

  const getUnitSitesByGroupName = async (
    localityNames: string[]
  ): Promise<(({ id: string; hierarchy: string[] } | undefined)[] | undefined)[]> =>
    Promise.all(
      localityNames.map(async (name: string) => {
        const groupRes = await callBslAndHandleErrors<EnterpriseGroupResponse, unknown>(
          {
            proxy: 'provisioning.enterprise-unit-groups',
            pathSegments: [getLocalityUUID(name)],
            verb: Req.Firebase.HttpVerb.GET,
          },
          handleError
        );
        if (isEnterpriseGroupResponse(groupRes)) {
          const results = Promise.all(
            groupRes.enterpriseUnits.map(async (unit: { enterpriseUnitId: string }) => {
              const { enterpriseUnitId } = unit;
              const site = await callBslAndHandleErrors<JurisdictionSitesRow, unknown>(
                {
                  proxy: 'site.v1',
                  pathSegments: ['sites', enterpriseUnitId],
                  verb: Req.Firebase.HttpVerb.GET,
                },
                handleError
              );
              if (site && String(site.status).toUpperCase() === 'ACTIVE')
                return { ...site, hierarchy: [name.replaceAll('_', ' '), site.id] };
              return undefined;
            })
          );
          return results;
        }
        return undefined;
      })
    );

  /**
   * END USER LICENSE AGREEMENTS
   */

  const getLrua = async (): Promise<LruaRecordData | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.schedules,
        pathSegments: ['lrua'],
        verb: Verb.GET,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const createLrua = async (paylod: LruaRecordRequest): Promise<LruaRecordData | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.schedules,
        pathSegments: ['lrua'],
        verb: Verb.POST,
        payload: paylod,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  return {
    getDefaultRuleConfigurationsByJurisdiction,
    getRuleConfigurationsForEnterprise,
    postRuleConfigurationForEnterprise,
    deleteRuleConfigurations,
    getUnitSitesByGroupName,
    getLrua,
    createLrua,
  };
};
