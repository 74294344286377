import { OrganizationSwitcher as RealOrgSwitcher } from '@ncr-voyix-commerce/react-common-components';
import MockOrganizationSwitcher from './MockOrgSwitcher/MockOrganizationSwitcher';

/**
 * In order to accommodate the new organization switcher within preview URLs,
 * we need to conditionally use a 'fake' org switcher that doesn't attempt
 * to make provisioning API requests, based on an environment variable
 * which should only be set to true during the CI pipeline.
 */
// eslint-disable-next-line import/no-mutable-exports
let OrganizationSwitcher = RealOrgSwitcher;

if (process.env.REACT_APP_USE_PREVIEW_ORG_SWITCHER === 'true') {
  OrganizationSwitcher = MockOrganizationSwitcher;
}

export default OrganizationSwitcher;
