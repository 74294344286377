const ruleConfigurationKeys = {
  all: ['labor-rules', 'config'] as const,
  listByJurisdiction: (jurisdiction: string, organizationId?: string) => [
    ...ruleConfigurationKeys.all,
    jurisdiction,
    organizationId,
  ],
  listForEnterprise: (jurisdiction: string, organizationId?: string) => [
    ...ruleConfigurationKeys.all,
    'org',
    jurisdiction,
    organizationId,
  ],
};

const siteKeys = {
  all: ['sites', 'find-by-criteria'] as const,
  sitesByJurisdiction: (jurisdiction: string) => [...siteKeys.all, jurisdiction],
};

const eulaKeys = {
  all: ['labor-rules'] as const,
  getEulaAgreement: (organizationId?: string) => [...eulaKeys.all, 'eula', organizationId],
  putEulaAgreement: () => [...eulaKeys.all, 'eula'],
};

const lruaKeys = {
  all: ['labor-rules', 'lrua'] as const,
};

const enterpriseUnitKeys = {
  all: ['provisioning', 'enterprise-unit-groups'] as const,
  enterpriseUnitGroup: (localityNames: string[], organizationId?: string) => [
    ...enterpriseUnitKeys.all,
    localityNames,
    organizationId,
  ],
};

const laborRulesKeys = {
  ruleConfiguration: ruleConfigurationKeys,
  site: siteKeys,
  enterpriseUnitKeys,
  eulaKeys,
  lruaKeys,
};

export default laborRulesKeys;
