/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/prefer-default-export */
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useLaborRulesRequests } from '.';
import { HttpsError } from '../../contexts/firebaseApiContext';
import { CreateChangeSetRequest } from '../models/LaborRuleConfiguration';
import { LruaRecordData, LruaRecordRequest } from '../models/LruaRecord';

export const handleEulaMutateError = (e: HttpsError) => {
  throw new Error('Fetching labor rules failed.');
};

export const handleSaveRuleConfigError = (e: HttpsError) => {
  throw new Error('Saving labor rule changes failed.');
};

export const useCreateLruaRecord = (): UseMutationResult<
  LruaRecordData | void,
  unknown,
  LruaRecordRequest,
  unknown
> => {
  const { createLrua } = useLaborRulesRequests();
  return useMutation((payload: LruaRecordRequest) => createLrua(payload).catch(handleEulaMutateError), {
    useErrorBoundary: true,
  });
};

export const useSaveNewRuleConfiguration = (): UseMutationResult<
  { body?: string } | void,
  unknown,
  CreateChangeSetRequest,
  unknown
> => {
  const { postRuleConfigurationForEnterprise } = useLaborRulesRequests();
  return useMutation(
    (payload: CreateChangeSetRequest) => postRuleConfigurationForEnterprise(payload).catch(handleSaveRuleConfigError),
    {
      useErrorBoundary: true,
    }
  );
};

export const useDeleteUpcomingRuleConfigurations = (): UseMutationResult<
  { body?: string } | void | unknown[],
  unknown,
  number[],
  unknown
> => {
  const { deleteRuleConfigurations } = useLaborRulesRequests();
  return useMutation(
    (changesetIds: number[]) => deleteRuleConfigurations(changesetIds).catch(handleSaveRuleConfigError),
    {
      useErrorBoundary: true,
    }
  );
};
