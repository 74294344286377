import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  height: string;
  padding: string;
  onCloseClick: () => void;
};

function SiteHeader(props: HeaderProps) {
  const { height, padding, onCloseClick } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        padding,
        height,
      }}
    >
      <Typography sx={{ padding: 0 }} variant='h6'>
        {t('globalFilters.sites')}
      </Typography>
      <IconButton onClick={onCloseClick} aria-label='Close'>
        <Close />
      </IconButton>
    </Box>
  );
}

export default SiteHeader;
