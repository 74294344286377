import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme, SxProps } from '@mui/material/styles';
import useScrollPosition from '../../utils/hooks/useScrollPosition';

type StickyFooterProps = {
  children: React.ReactNode;
  position?: 'fixed' | 'sticky';
  addBoxShadow?: boolean;
  customSx?: SxProps;
};

function StickyFooter({ children, position, addBoxShadow, customSx }: StickyFooterProps) {
  const theme = useTheme();
  const { isAtBottomOfPage } = useScrollPosition();

  return (
    <>
      <AppBar
        position={position}
        sx={{
          top: 'auto',
          bottom: 0,
          backgroundColor: theme.palette.background.default,
          border: 'none',
          boxShadow: isAtBottomOfPage || !addBoxShadow ? 0 : '0px -4px 12px 0px rgba(0,0,0,0.08)',
          ...customSx,
        }}
        data-testid='sticky-footer'
      >
        {children}
      </AppBar>
      <Toolbar />
    </>
  );
}

StickyFooter.defaultProps = {
  position: 'fixed',
  addBoxShadow: true,
  customSx: undefined,
};

export default StickyFooter;
