/* eslint-disable import/prefer-default-export */
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useLaborRulesRequests } from '.';
import { LaborRuleConfigData } from '../models/LaborRuleConfiguration';
import queryKeys from './queryKeys';
import { JurisdictionSitesRow } from '../JurisdictionSitesDataGrid/JurisdictionSitesDataGrid';
import { HttpsError, transformFirebaseFunctionsErrorCode } from '../../contexts/firebaseApiContext';
import staleTimeSettings from '../../config/reactQueryConfig';

export const useDefaultLaborRulesConfigurationsQuery = (
  jurisdiction: string,
  hasEula: boolean,
  organizationId?: string
): UseQueryResult<Array<LaborRuleConfigData>> => {
  const { getDefaultRuleConfigurationsByJurisdiction } = useLaborRulesRequests();

  return useQuery(
    queryKeys.ruleConfiguration.listByJurisdiction(jurisdiction, organizationId),
    () => getDefaultRuleConfigurationsByJurisdiction(jurisdiction),
    {
      enabled: hasEula,
    }
  );
};

export const useLaborRulesForEnterpriseQuery = (
  jurisdiction: string,
  hasEula: boolean,
  organizationId?: string
): UseQueryResult<Array<LaborRuleConfigData>> => {
  const { getRuleConfigurationsForEnterprise } = useLaborRulesRequests();

  return useQuery(
    queryKeys.ruleConfiguration.listForEnterprise(jurisdiction, organizationId),
    () => getRuleConfigurationsForEnterprise(jurisdiction),
    {
      enabled: hasEula,
      refetchOnWindowFocus: false,
    }
  );
};

export const handleEulaFetchError = (e: HttpsError) => {
  const firebaseStatus = e.code;
  const statusCode = transformFirebaseFunctionsErrorCode(firebaseStatus);
  if (statusCode === 404) {
    /**
     * 404 = user has not signed the agreement
     * No need to handle error. Flow continues.
     */
    return e;
  }
  // Everything else is an error
  throw new Error('Fetching labor rules failed.');
};

export const useGetLruaQuery = () => {
  const { getLrua } = useLaborRulesRequests();

  return useQuery(queryKeys.lruaKeys.all, () => getLrua(), {
    enabled: true,
    useErrorBoundary: false,
    staleTime: staleTimeSettings.MEDIUM,
    refetchOnWindowFocus: false,
  });
};

export const useSitesByEnterpriseUnitGroup = (
  localityNames: string[],
  hasEula: boolean,
  organizationId?: string
): UseQueryResult<Array<JurisdictionSitesRow>> => {
  const { getUnitSitesByGroupName } = useLaborRulesRequests();

  return useQuery(
    queryKeys.enterpriseUnitKeys.enterpriseUnitGroup(localityNames, organizationId),
    () => getUnitSitesByGroupName(localityNames),
    {
      enabled: hasEula,
    }
  );
};
