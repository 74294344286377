import { FormContainer } from 'react-hook-form-mui';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import Ty from '@mui/material/Typography';
import { useEffect } from 'react';
import DialogWrapper from '../../components/Dialog/DialogWrapper';
import useYupValidationResolver from '../../utils/formUtils/yupValidationResolver';
import createFormTextField from '../../components/FormTextFields/FormTextFields';

const warningList = [
  'laborSettingsForSchedule.changeWarningDialog.historicalDiscrepancy',
  'laborSettingsForSchedule.changeWarningDialog.reportingMismatch',
];

const CHANGE_CONFIRMATION_TEXT = 'change';

export default function LaborSettingsScheduleChangeWarning({
  open,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    confirmationInput: yup
      .string()
      .required()
      .test(
        'match-confirmation-word',
        t(`laborSettingsForSchedule.changeWarningDialog.confirmationWordMismatchPrompt`),
        (value) => value === CHANGE_CONFIRMATION_TEXT
      ),
  });

  const formContext = useForm({
    defaultValues: { confirmationInput: '' },
    resolver: useYupValidationResolver(validationSchema),
  });

  const { register, reset } = formContext;

  const handleSubmit = async (data: { confirmationInput: string }) => {
    await onConfirm();
    reset();
  };

  useEffect(() => {
    reset('');
  }, [reset, open]);

  const cancelled = () => {
    onCancel();
    reset();
  };
  return (
    <DialogWrapper
      title={t(`laborSettingsForSchedule.changeWarningDialog.dialogTitle`)}
      dialogOptions={{
        open,
        onClose: onCancel,
        maxWidth: 'xs',
        fullWidth: true,
      }}
    >
      <FormContainer formContext={formContext} onSuccess={handleSubmit}>
        <DialogContent data-testid='confirm-dialog'>
          <Ty color='text.secondary'>{t(`laborSettingsForSchedule.changeWarningDialog.changeEffects`)}</Ty>
          <ul>
            {warningList.map((listItem) => (
              <li key={uuidv4()}>
                <Ty color='text.secondary'>{t(listItem)}</Ty>
              </li>
            ))}
          </ul>
          <Ty mt={2} color='text.secondary'>
            <Trans
              i18nKey='laborSettingsForSchedule.changeWarningDialog.confirmationTextPrompt'
              values={{ CHANGE_CONFIRMATION_TEXT }}
            />
          </Ty>
          <Box mt={3}>
            {createFormTextField(
              {
                name: 'confirmationInput',
                variant: 'standard',
                required: true,
                dataTestId: 'confirmation-text-field',
                label: '',
              },
              { t, tKey: 'labor' },
              register
            )}
          </Box>
          <Ty color='text.secondary' mt={1}>
            {t(`laborSettingsForSchedule.changeWarningDialog.cancelPrompt`)}
          </Ty>
        </DialogContent>
        <DialogActions>
          <Button data-testid='confirm-btn' color='error' variant='contained' type='submit'>
            {t('Confirm')}
          </Button>
          <Button data-testid='cancel-btn' onClick={cancelled} color='secondary'>
            {t('Cancel')}
          </Button>
        </DialogActions>
      </FormContainer>
    </DialogWrapper>
  );
}
