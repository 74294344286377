import BusinessIcon from '@mui/icons-material/Business';
import StoreMallDirectory from '@mui/icons-material/StoreMallDirectory';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { styled, Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import { SiteInfo } from '@cbo/shared-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { baseRoutes } from '../../constants/routes';
import { useSites } from '../../contexts/siteContext';
import { getSiteButtonText } from '../utils/siteUtils';
import SiteSelectorMenu from './SiteSelectorMenu';
import { appBarButtonSx, appBarButtonSxMobile } from '../../lib/styles/commonStyles';
import SiteSelectorDrawer from './SiteSelectorDrawer';
import SiteSelectorDialog from './SiteSelectorDialog';

interface SiteSelectorProps {
  isOrgView: boolean;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
    textAlign: 'center',
  },
}));

function SiteSelector({ isOrgView }: SiteSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { selectedSite, isSiteFiltering, multiSelectedSites, sortedSites, updateSelectedSite } = useSites();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSiteSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile) {
      setDrawerOpen(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleSiteSelection = (site: SiteInfo) => {
    setDialogOpen(false);
    setAnchorEl(null);
    setDrawerOpen(false);

    if (site.enterpriseUnitId !== selectedSite.enterpriseUnitId) {
      const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

      // Check if the current route has a GUID, which will inevitably be site specific. If so
      // we want to route the user away so they won't encounter a 404
      if (uuidRegex.test(window.location.href)) {
        const baseRoute = baseRoutes.find((path) => window.location.pathname.includes(path));
        if (baseRoute) {
          // The appended ?siteChange optional param will indicate that only this routing
          // event should trigger a site change if user accepts it with unsaved changes.
          const path = `${baseRoute}?siteChange`;
          // If the user has unsaved changes, this will track if they choose to cancel the
          // navigation or continue. If they continue, this will update the site automatically.
          const unregister = history.listen((location) => {
            if (location.pathname + location.search === path) updateSelectedSite(site);

            // TBH this is a bit of a hack, to remove the appended ?siteChange
            window.history.replaceState(null, '', window.location.pathname);

            // This unregister is super important, because we only want to check the first route change
            // that happens after this function is called, and if that route change doesn't result in
            // the user going to that url we expect we don't want the site to change on the following route change
            unregister();
          });
          history.push(path);
        }
      } else {
        updateSelectedSite(site);
      }
    }
  };

  const handleViewAll = () => {
    setDialogOpen(true);
    setAnchorEl(null);
    setDrawerOpen(false);
  };

  const topSites = sortedSites.slice(0, 7).map((option) => (
    <MenuItem
      onClick={() => handleSiteSelection(option)}
      key={option.enterpriseUnitId}
      selected={option.enterpriseUnitId === selectedSite?.enterpriseUnitId}
      sx={{ padding: '12px 8px 12px 12px', borderRadius: '8px', margin: '4px 12px' }}
    >
      <Typography variant='body1'>{option.name}</Typography>
    </MenuItem>
  ));

  const buttonText = !selectedSite.name?.length
    ? t('global.loading')
    : getSiteButtonText(
        selectedSite.name ?? '',
        multiSelectedSites,
        sortedSites.length,
        isSiteFiltering,
        true,
        t('globalFilters.sites'),
        t('globalFilters.allSites')
      );

  return isOrgView ? (
    <CustomTooltip arrow title={t('global.organizationViewTooltipText')}>
      <span>
        <Button disabled data-testid='org-view-tooltip-btn' startIcon={<StoreMallDirectory />} endIcon={<UnfoldMore />}>
          {t('navItems.organizationView')}
        </Button>
      </span>
    </CustomTooltip>
  ) : (
    <>
      <LoadingButton
        onClick={handleSiteSelectorClick}
        aria-label={buttonText}
        data-testid='site-selector-btn'
        startIcon={selectedSite?.enterpriseUnitId !== '' ? <StoreMallDirectory /> : <BusinessIcon />}
        endIcon={isMobile ? null : <UnfoldMore />}
        aria-haspopup='true'
        aria-expanded={anchorEl ? 'true' : undefined}
        disabled={isSiteFiltering}
        sx={isMobile ? appBarButtonSxMobile : appBarButtonSx}
        loading={!selectedSite.name?.length}
        loadingPosition='start'
      >
        {isMobile ? null : buttonText}
      </LoadingButton>
      <SiteSelectorMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleViewAll={handleViewAll}
        topSites={topSites}
      />
      <SiteSelectorDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        topSites={topSites}
        handleViewAll={handleViewAll}
      />
      <SiteSelectorDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleSiteSelection={handleSiteSelection}
      />
    </>
  );
}

export default SiteSelector;
