import { AuthorizedOrganization } from '@cbo/shared-library/types/bslCore/provisioning';
import {
  ApplicationsContextProvider,
  IOrganizationContext,
  Organization,
  RccContextConfig,
  RccContextProvider,
  RccEnvironment,
  useOrganizationContext,
} from '@ncr-voyix-commerce/react-common-components';
import { useOktaAuth } from '@okta/okta-react';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import formatOrganizations from './utils';

const contextValue: IOrganizationContext = {
  totalOrganizations: undefined,
  hasPreloaded: false,
  isLoadingOrg: false,
  organization: undefined,
  isOrgDialogOpen: false,
  setIsOrgDialogOpen: {} as Dispatch<SetStateAction<boolean>>,
  userOrganizations: [],
  updateOrganization: () => null,
  error: undefined,
  isLoading: true,
};

export const OrgContext = createContext<IOrganizationContext>(contextValue);

const sharedConfig: RccContextConfig = {
  // TODO: Use locale once localization options are implemented
  language: 'en',
  environment: (process.env.REACT_APP_RCC_CONFIG_ENV as RccEnvironment | undefined) ?? 'dev',
  enableAutoLogout: true,
};

/**
 * This wrapper around RccContextProvider is meant to conditionally return our
 * own version of the organization context provider if the environment variable
 * denoting the use of the preview environment is set to true. Otherwise, we want
 * to use the regular RccContextProvider and organization switcher component.
 */
export function CommonComponentWrapper({ children }: { children: ReactNode }) {
  const { authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [userOrganizations, setUserOrganizations] = useState<Organization[]>([]);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);
  const [organization, setOrganization] = useState<Organization | undefined>(undefined);

  useEffect(() => {
    const selectedOrg = userOrganizations.find((org) => org.organizationName === organizationName);
    setOrganization(selectedOrg || undefined);
  }, [organizationName, userOrganizations]);

  const updateOrganization = useCallback(
    async (orgName: string | undefined) => {
      setOrganizationName(orgName ?? null);
    },
    [setOrganizationName]
  );

  const orgContextValue: IOrganizationContext = useMemo(
    () => ({
      organization,
      isOrgDialogOpen,
      setIsOrgDialogOpen,
      userOrganizations,
      updateOrganization,
      isLoading,
      error: undefined,
      totalOrganizations: undefined,
      hasPreloaded: false,
      isLoadingOrg: false,
    }),
    [organization, isOrgDialogOpen, setIsOrgDialogOpen, userOrganizations, updateOrganization, isLoading]
  );

  useEffect(() => {
    (async () => {
      if (process.env.REACT_APP_USE_PREVIEW_ORG_SWITCHER !== 'true' || !authState?.accessToken?.accessToken) return;

      // We must make a raw request to the firebase function here, rather than using
      // the firebase API context, because the API context is not yet initialized at this point
      const res = await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_HOST}/geteffectiveorganizations`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `AccessToken ${authState.accessToken.accessToken}`,
        },
        method: 'post',
        body: JSON.stringify({ data: {} }),
      });
      const json = await res.json();
      const orgs: Organization[] = (json.pageContent as AuthorizedOrganization[]).map((org) => ({
        ...org,
        linesOfBusiness: [],
        children: [],
        parent: false,
        description: '',
      }));
      const formattedOrgs = formatOrganizations(orgs);
      setOrganizationName(formattedOrgs[0]?.organizationName ?? null);
      setUserOrganizations(formattedOrgs ?? []);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.accessToken?.accessToken]);
  return (
    <RccContextProvider config={sharedConfig}>
      <ApplicationsContextProvider>
        {process.env.REACT_APP_USE_PREVIEW_ORG_SWITCHER === 'true' ? (
          <OrgContext.Provider value={orgContextValue}>{children}</OrgContext.Provider>
        ) : (
          children
        )}
      </ApplicationsContextProvider>
    </RccContextProvider>
  );
}

export default CommonComponentWrapper;

// eslint-disable-next-line import/no-mutable-exports
export let useOrgContext = useOrganizationContext;

if (process.env.REACT_APP_USE_PREVIEW_ORG_SWITCHER === 'true') {
  useOrgContext = () => useContext(OrgContext);
}
