import { useTheme } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import { SetStateAction, Dispatch } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';

type Props = {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  handleViewAll: () => void;
  topSites: JSX.Element[];
};

function SiteSelectorMenu(props: Props) {
  const { anchorEl, setAnchorEl, handleViewAll, topSites } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      data-testid='site-selector-menu'
      sx={{
        '.MuiPaper-root': {
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        },
      }}
      MenuListProps={{
        sx: { width: '380px', paddingTop: '4px', paddingBottom: 0 },
      }}
    >
      <MenuItem
        disabled
        sx={{
          fontWeight: 500,
          fontSize: theme.typography.body2.fontSize,
          color: theme.palette.text.secondary,
          padding: '0px 16px 0px 20px',
          '&.Mui-disabled': {
            opacity: 1,
          },
          height: '48px',
        }}
      >
        {t('global.sites')}
      </MenuItem>
      {topSites}
      <Divider
        sx={{
          '&.MuiDivider-root.MuiDivider-fullWidth': {
            marginBottom: 0,
          },
        }}
      />
      <MenuItem
        sx={{
          fontWeight: 500,
          fontSize: theme.typography.body2.fontSize,
          color: theme.palette.primary.dark,
          height: '64px',
          padding: '20px 0px 20px 20px',
        }}
        onClick={() => handleViewAll()}
        data-testid='view-all-sites'
      >
        {t('globalFilters.viewAllSites')}
        <ArrowForwardIcon sx={{ fontSize: '20px', marginLeft: '8px' }} />
      </MenuItem>
    </Menu>
  );
}

export default SiteSelectorMenu;
