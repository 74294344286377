import Info from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ThemeOptions, alpha } from '@mui/material/styles';

export const commonThemeOptions: ThemeOptions = {
  typography: {
    button: { textTransform: 'none' },
    fontFamily: `Inter Variable, sans-serif`,
    display1: {
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 2.5,
    },
    display2: {
      fontWeight: 600,
      fontSize: '1.8125rem',
      lineHeight: 2.2475,
    },
    h1: {
      fontWeight: 700,
      lineHeight: 2.031,
      fontSize: '1.5625rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.5075,
    },
    h3: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.75,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.25125,
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.25125,
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.995,
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.245,
    },
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        disableRipple: false,
        disableFocusRipple: false,
        disableTouchRipple: false,
        focusRipple: false,
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          color: ownerState.color === 'default' ? theme.palette.action.active : ownerState.color,
          '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '8px',
          },
          '&:hover': {
            borderRadius: '8px',
          },
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
        disableTouchRipple: false,
        focusRipple: false,
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          boxShadow: 'none',
          '&.MuiButton-sizeLarge': {
            padding: '10px 22px',
          },
          '&.MuiButton-sizeMedium': {
            padding: '8px 16px',
          },
          '&.MuiButton-sizeSmall': {
            padding: '4px 10px',
          },
          '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: `inset 0 0 0 1px ${theme.palette.primary.contrastText}`,
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: false,
        focusRipple: false,
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          boxShadow: 'none',
          borderRadius: '8px',
          '&:hover': {
            boxShadow: 'none',
            '&.MuiButton-contained': {
              background: ownerState.color && ownerState.color !== 'inherit' && theme.palette[ownerState.color].dark,
            },
            '&.MuiButton-outlined .MuiButton-text': {
              background:
                ownerState.color && ownerState.color !== 'inherit' && alpha(theme.palette[ownerState.color].main, 0.04),
            },
            '&.MuiButton-outlined': {
              borderColor:
                ownerState.color && ownerState.color !== 'inherit' && alpha(theme.palette[ownerState.color].main, 0.6),
            },
          },
          '&.MuiButton-sizeLarge': {
            padding: '10px 22px',
          },
          '&.MuiButton-sizeMedium': {
            padding: '8px 16px',
          },
          '&.MuiButton-sizeSmall': {
            padding: '4px 10px',
          },
          '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: `inset 0 0 0 1px ${theme.palette.primary.contrastText}`,
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          backgroundColor:
            theme.palette[ownerState.color && ownerState.color !== 'default' ? ownerState.color : 'primary'].subtle,
          color:
            theme.palette[ownerState.color && ownerState.color !== 'default' ? ownerState.color : 'primary']
              .subtleContrast,
          fontSize: '0.8125rem',
          fontWeight: 500,
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <Info />,
          error: <ErrorIcon />,
          success: <CheckCircleIcon />,
          warning: <ReportProblemIcon />,
        },
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          backgroundColor: theme.palette[ownerState.severity ? ownerState.severity : 'info'].subtle,
          color: theme.palette[ownerState.severity ? ownerState.severity : 'info'].subtleContrast,
          borderRadius: '8px',
          alignItems: 'center',
        }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: { boxShadow: 'none' },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        focusRipple: false,
      },
      styleOverrides: {
        root: { borderRadius: '8px' },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          padding: 6,
          marginBottom: '5px',
          '&:hover': {
            backgroundColor:
              ownerState.color && ownerState.checked
                ? alpha(theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color].main, 0.04)
                : theme.palette.action.hover,
            borderRadius: '19px',
          },
          '& .MuiSwitch-switchBase': {
            padding: 8,
            '&.Mui-checked': {
              '& + .MuiSwitch-track': {
                backgroundColor:
                  ownerState.color && ownerState.color !== 'default'
                    ? theme.palette[ownerState.color].main
                    : theme.palette.primary.main,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor:
                  ownerState.color && ownerState.color !== 'default'
                    ? theme.palette[ownerState.color].subtle
                    : theme.palette.primary.subtle,
              },
              '&:hover': {
                background: 'none',
              },
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: theme.palette.action.disabledBackground,
            },
            '&:hover': {
              background: 'none',
            },
            '&.Mui-focusVisible': {
              background:
                ownerState.color && ownerState.checked
                  ? alpha(theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color].main, 0.3)
                  : theme.palette.action.focus,
              borderRadius: '19px',
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            color:
              ownerState.color && ownerState.color !== 'default'
                ? theme.palette[ownerState.color].contrastText
                : theme.palette.primary.contrastText,
            width: 22,
            height: 22,
            boxShadow: 'none',
          },
          '& .MuiSwitch-track': {
            borderRadius: 38 / 2,
            backgroundColor: theme.palette.action.active,
            opacity: 1,
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '&:hover': {
            background:
              ownerState.color && ownerState.color !== 'default'
                ? alpha(theme.palette[ownerState.color].main, 0.04)
                : theme.palette.action.hover,
          },
          '&:focus': {
            background:
              ownerState.color && ownerState.color !== 'default'
                ? alpha(theme.palette[ownerState.color].main, 0.3)
                : theme.palette.action.focus,
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '&:hover': {
            background:
              ownerState.color && ownerState.color !== 'default'
                ? alpha(theme.palette[ownerState.color].main, 0.04)
                : theme.palette.action.hover,
          },
          '&:focus': {
            background:
              ownerState.color && ownerState.color !== 'default'
                ? alpha(theme.palette[ownerState.color].main, 0.3)
                : theme.palette.action.focus,
          },
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          marginTop: -5,
          marginLeft: ownerState.variant === 'outlined' ? -10 : undefined,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          marginLeft: ownerState.variant === 'outlined' ? 2 : undefined,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginBottom: '5px',
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '8px',
          height: ownerState.variant === 'outlined' ? '44px' : undefined,
        }),
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          height: '44px',
          marginTop: 10,
        },
      },
    },
  },
};

export const commonPaletteOptions = {
  common: { black: '#000', white: '#fff' },
  grey: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#F5F5F5',
    A200: '#EEEEEE',
    A400: '#BDBDBD',
    A700: '#616161',
  },
  tonalOffset: 0.5,
  contrastThreshold: 4.5,
};

export const dsCommonThemeOptions: ThemeOptions = {
  typography: {
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: '#656565',
    },
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        disableRipple: false,
        disableFocusRipple: false,
        disableTouchRipple: false,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
        disableTouchRipple: false,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: false,
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <Info />,
          error: <ErrorIcon />,
          success: <CheckCircleIcon />,
          warning: <ReportProblemIcon />,
        },
      },
    },
  },
};
