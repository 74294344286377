import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrgContext } from '../CommonComponentWrapper';
import DialogListItemActionButton from './DialogListItemActionButton';
import { useOrganizationDialogContext } from './OrganizationDialogProvider';

function OrganizationAllOrgsList() {
  const { t } = useTranslation();
  const { userOrganizations, organization } = useOrgContext();
  const { handleOrganizationClick, searchValue } = useOrganizationDialogContext();

  const filteredOrgs = userOrganizations.filter((org) => org.organizationName.includes(searchValue));

  if (filteredOrgs.length === 0) {
    return (
      <Box sx={{ px: '24px' }}>
        <Typography variant='body1'>{t('mockOrgSwitcher.dialog.noResults')}</Typography>
      </Box>
    );
  }

  return (
    <List>
      {filteredOrgs.map((org) => {
        const orgName = org.organizationName;

        return (
          <Fragment key={`${orgName}`}>
            <DialogListItemActionButton
              selected={orgName === organization?.organizationName}
              onListItemButtonClick={() => handleOrganizationClick(orgName)}
              sx={{
                px: 0,
                py: 0,
                '.MuiListItemSecondaryAction-root': {
                  right: '8px',
                },
              }}
            >
              <Typography variant='body1'>{orgName}</Typography>
            </DialogListItemActionButton>
          </Fragment>
        );
      })}
    </List>
  );
}

export default OrganizationAllOrgsList;
