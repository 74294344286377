import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { SiteInfo } from '@cbo/shared-library';
import { useSites } from '../../contexts/siteContext';

type AllSitesListProps = {
  searchValue: string;
  handleSiteSelection: (site: SiteInfo) => void;
};

function AllSitesList(props: AllSitesListProps) {
  const { searchValue, handleSiteSelection } = props;
  const { t } = useTranslation();
  const { selectedSite, sortedSites } = useSites();

  const filteredSites = sortedSites.filter(
    (site: SiteInfo) =>
      site.referenceId?.toLowerCase().includes(searchValue.toLowerCase()) ||
      site.name?.toLowerCase().includes(searchValue.toLowerCase())
  );

  if (filteredSites.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', height: '100px', alignItems: 'center' }}>
        <Typography variant='body1'>{t('multiselect.noResults')}</Typography>
      </Box>
    );
  }

  return (
    <MenuList sx={{ padding: 0 }}>
      {filteredSites.map((site) => (
        <MenuItem
          key={site.enterpriseUnitId}
          onClick={() => handleSiteSelection(site)}
          selected={site.enterpriseUnitId === selectedSite?.enterpriseUnitId}
          sx={{ height: '48px', margin: '4px 0px', borderRadius: '8px' }}
        >
          <Typography variant='body1'>{site.name}</Typography>
        </MenuItem>
      ))}
    </MenuList>
  );
}

export default AllSitesList;
